var $ = jQuery;
(function($) {
	'use strict';
	$(document).ready(function(){   
    $('.mean-menu').meanmenu({ 
			meanScreenWidth: "991"
		});
		$(window).on('scroll', function() {
			if ($(this).scrollTop() > 45){  
					$('.navbar-area').addClass("sticky-nav");
			}
			else{
					$('.navbar-area').removeClass("sticky-nav");
			}
		});
		$('#datetimepicker').datetimepicker({theme:'dark'});
		$('#datetimepicker-check').datetimepicker();
		new WOW().init();
		$('select').niceSelect();
		$('body').append('<div id="toTop" class="top-btn"><i class="bx bx-chevrons-up"></i></div>');
    $(window).on('scroll',function () {
			if ($(this).scrollTop() != 0) {
					$('#toTop').fadeIn();
			} else {
					$('#toTop').fadeOut();
			}
    }); 
    $('#toTop').on('click',function(){
			$("html, body").animate({ scrollTop: 0 }, 1000);
			return false;
    });


    var urlHash = window.location.href.split("#")[1];
		var booking = 'booking';
    if (urlHash && booking)
			$('html,body').animate({
				scrollTop: $('#' + urlHash).offset().top - 70
		}, 1000);

    $(window).on('load',function(){
			$(".preloader").fadeOut(500);
		});

		var themeColor;

		if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			if (localStorage.getItem('theme')) {
				themeColor = localStorage.getItem('theme');
			} else {
				themeColor ='theme-dark';
				$('.switch-box input').prop('checked', false);
			}
		} else {
			if (localStorage.getItem('theme')) {
				themeColor = localStorage.getItem('theme');
			} else {
				themeColor ='theme-light';
				$('.switch-box input').prop('checked', true);
			}
			$('.switch-box input').prop('checked', true);
		}

		localStorage.setItem('theme', themeColor);

		if (localStorage.getItem('theme') =='theme-dark') {
			document.documentElement.className = 'theme-dark';
			$('.switch-box input').prop('checked', false);
		} else {
			$('.switch-box input').prop('checked', true);
		}

    $('.switch-box .slider').click(function() {
			var checkTheme = $('.switch-box input');
			checkTheme.prop('checked', function (i, value) {
				if (!value == true) {
					localStorage.setItem('theme', 'theme-light');
					document.documentElement.className = 'theme-light';
				} else {
					localStorage.setItem('theme', 'theme-dark');
					document.documentElement.className = 'theme-dark';
				}
			});
		});
		$('body.home .about-img').owlCarousel({
			loop: true,
			margin: 30,
			nav: false,
			dots: true,
			autoplay: true,
			autoplayHoverPause: true,
			items: 1
		});
		$('.accordion').find('.accordion-title').on('click', function(){
			// Adds Active Class
			$(this).toggleClass('active');
			// Expand or Collapse This Panel
			$(this).next().slideToggle('fast');
			// Hide The Other Panels
			$('.accordion-content').not($(this).next()).slideUp('fast');
			// Removes Active Class From Other Titles
			$('.accordion-title').not($(this)).removeClass('active');		
		});
		$('.wp-block-gallery').each(function(index) {
			var numItems = index + 1;
			$('.wp-block-gallery-'+numItems +' .wp-block-image').magnificPopup({
				delegate: 'a',
				type: 'image',
				tLoading: 'Loading image #%curr%...',
				mainClass: 'mfp-img-mobile',
				gallery: {
						enabled: true,
						navigateByImgClick: true,
						preload: [0,1] 
				}
			});
		}); 
	});
}(jQuery));